export const siteMapData = ({ t }) => [
  {
    name: 'SOF S.P.A.',
    office1:
      `Sede Legale e Operativa: Via G. del Pian dei Carpini 1 - 50127 Firenze`,
    register: '',
    legal: `Registro delle Imprese di Firenze<br />
    C.F. e P.IVA  05481180486<br />
    N. R.E.A. FI - 550126<br />`,
    cap: `
    Capitale Sociale i.v. € 5.000.000,00`,
    soc: `Società con socio Unico<br />
    Società soggetta al coordinamento di Fincantieri Infrastrutture Sociali S.p.A.`,
    contacts: `
    CONTATTI<br />
    <a href="mailto:segreteria@sof.it">segreteria@sof.it</a><br />
    <a href="mailto:legalmail@pec.sof.it">legalmail@pec.sof.it</a><br />
    <a href="https://www.sof.it">www.sof.it</a>
    `,
  },
  {

  },
];