import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import common_it from './translations/it/common.json';
import common_en from './translations/en/common.json';
import menu_it from './translations/it/menu.json';
import menu_en from './translations/en/menu.json';
import pages_it from './translations/it/pages.json';
import pages_en from './translations/en/pages.json';

import theme from './theme';
import 'shared/index.css';
import 'shared/dist/index.css';
import './index.css';

const langFromLocalStorage = localStorage.getItem('language');

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: langFromLocalStorage ? langFromLocalStorage : 'it', // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
      menu: menu_en,
      pages: pages_en,
    },
    it: {
      common: common_it,
      menu: menu_it,
      pages: pages_it,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <MuiThemeProvider theme={theme}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </MuiThemeProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
